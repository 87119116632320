import Logo from "./Logo";
import "../styles/signin.css";

const HeaderRegister = () => {
  return (
    <header className="header-register">
      <Logo />
    </header>
  );
};

export default HeaderRegister;
