export const avatarURLs = [
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Sammy&randomizeIds=true&accessories=glasses,glasses2,eyepatch,glasses3,glasses4,glasses5,sunglasses,sunglasses2&backgroundColor[]",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Boots&randomizeIds=true&accessories=glasses,glasses2,eyepatch,glasses3,glasses4,glasses5,sunglasses,sunglasses2&backgroundColor[]",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Fluffy&randomizeIds=true&accessories=glasses,glasses2,eyepatch,glasses3,glasses4,glasses5,sunglasses,sunglasses2&backgroundColor[]",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Mia&randomizeIds=true&accessories=glasses,glasses2,eyepatch,glasses3,glasses4,glasses5,sunglasses,sunglasses2&backgroundColor[]",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Lucy&randomizeIds=true&accessories=glasses,glasses2,eyepatch,glasses3,glasses4,glasses5,sunglasses,sunglasses2&backgroundColor[]",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Lilly&randomizeIds=true&accessories=glasses,glasses2,eyepatch,glasses3,glasses4,glasses5,sunglasses,sunglasses2&backgroundColor[]",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Bob&randomizeIds=true&accessories=glasses,glasses2,eyepatch,glasses3,glasses4,glasses5,sunglasses,sunglasses2&backgroundColor[]",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Coco&randomizeIds=true&accessories=glasses,glasses2,eyepatch,glasses3,glasses4,glasses5,sunglasses,sunglasses2&backgroundColor[]",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Lily&randomizeIds=true&accessories=glasses,glasses2,eyepatch,glasses3,glasses4,glasses5,sunglasses,sunglasses2&backgroundColor[]",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Harley&randomizeIds=true&accessories=glasses,glasses2,eyepatch,glasses3,glasses4,glasses5,sunglasses,sunglasses2&backgroundColor[]",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Callie&randomizeIds=true&accessories=glasses,glasses2,eyepatch,glasses3,glasses4,glasses5,sunglasses,sunglasses2&backgroundColor[]",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Maggie&randomizeIds=true&accessories=glasses,glasses2,eyepatch,glasses3,glasses4,glasses5,sunglasses,sunglasses2&backgroundColor[]",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Ginger&randomizeIds=true&accessories=glasses,glasses2,eyepatch,glasses3,glasses4,glasses5,sunglasses,sunglasses2&backgroundColor[]",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Jasper&randomizeIds=true&accessories=glasses,glasses2,eyepatch,glasses3,glasses4,glasses5,sunglasses,sunglasses2&backgroundColor[]",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=George&randomizeIds=true&accessories=glasses,glasses2,eyepatch,glasses3,glasses4,glasses5,sunglasses,sunglasses2&backgroundColor[]",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Gizmo&randomizeIds=true&accessories=glasses,glasses2,eyepatch,glasses3,glasses4,glasses5,sunglasses,sunglasses2&backgroundColor[]",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Bella&randomizeIds=true&accessories=glasses,glasses2,eyepatch,glasses3,glasses4,glasses5,sunglasses,sunglasses2&backgroundColor[]",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Pepper&randomizeIds=true&accessories=glasses,glasses2,eyepatch,glasses3,glasses4,glasses5,sunglasses,sunglasses2&backgroundColor[]",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Annie&randomizeIds=true&accessories=glasses,glasses2,eyepatch,glasses3,glasses4,glasses5,sunglasses,sunglasses2&backgroundColor[]",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Oscar",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Zoe",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Sugar",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Snickers",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Zoey",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Missy",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Toby",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Midnight",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Precious",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Mittens",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Smokey",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Boots",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Sophie",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Bear",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Felix",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Princess",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Snowball",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Oliver",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Kiki",
  "https://api.dicebear.com/7.x/open-peeps/svg?seed=Milo",
];
